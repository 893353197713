import { Typography, Divider, Box, Button, TextField, Snackbar, Modal } from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import MuiAlert from '@mui/material/Alert';
import React, { useRef, useState, KeyboardEvent, useContext } from "react";
import { ContentWrapper, SectionWrapper, ModalStyle } from './styles';
import SampleRegistration from "../../Models/SampleRegistration";
import { GetRegisteredPatient, UpsertPatient, SpecimenReceived, SpecimenError, GetPatients } from "../../Graphql/Api";
import Patient from "../../Models/Patient";
import PatientConsentStatement from '../../Models/PatientConsentStatement';
import PatientWorkflowSteps from '../../Models/PatientWorkflowSteps';
import { ManifestsDestinationOption } from '../../Models/Constants/ManifestsDestinationOption';
import { UpdatePatientFirstName, UpdatePatientLastName, UpdatePatientDateOfBirth, UpdatePatientGender, UpdatePatientEmail } from './utils';
import { AuthContext } from '../../Context/Auth/AuthContext';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GraphqlExceptionLogging from "../../Utils/GraphqlExceptionLogging";

const SpecimenReceptionPage = () => {
    const [currentSampleRegistration, setCurrentSampleRegistration] = useState<SampleRegistration | null>(null);
    const [patientSampleCollectionDate, setPatientSampleCollectionDate] = useState<Date | null>(null);
    const [patientSampleErrorNote, setPatientSampleErrorNote] = useState<string>("");

    const [toastOpen, setToastOpen] = useState<boolean>(false);
    const [toastError, setToastError] = useState<string>("");

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalTextElement, setModalTextElement] = useState<JSX.Element>(<></>);

    const authContext = useContext(AuthContext);
    const sampleIdRef = useRef<HTMLInputElement | null>(null);

    const hasVcgsLabError = (sampleRegistration: SampleRegistration | null) => {
        return sampleRegistration?.previousSample?.workflowSteps?.find(workflow => workflow.name.toLowerCase() === "vcgslabprocessing" && workflow.hasError &&
                                                                       !["recollect_required_exome_data", "recollect_required_exome_data_contamination"].includes(workflow.errorCode?.toLowerCase())) != null;
    }

    const hasVcgsLabResponse = (sampleRegistration: SampleRegistration | null) => {
        return sampleRegistration?.previousSample?.workflowSteps?.find(workflow => workflow.name.toLowerCase() === "vcgslabprocessing") != null;
    }

    const isVcgsLabCompleted = (sampleRegistration: SampleRegistration | null) => {
        return sampleRegistration?.previousSample?.workflowSteps?.find(workflow => workflow.name.toLowerCase() === "vcgslabprocessing" && workflow.isCompleted) != null;
    }

    const hasGeneByGeneLabError = (sampleRegistration: SampleRegistration | null) => {
        return (sampleRegistration?.previousSample?.workflowSteps?.find(workflow => workflow.name.toLowerCase() === "gxglabprocessing" && workflow.hasError) != null) ||
               (sampleRegistration?.previousSample?.workflowSteps?.find(workflow => workflow.name.toLowerCase() === "vcgslabprocessing" && workflow.hasError &&
                                                                        ["recollect_required_exome_data", "recollect_required_exome_data_contamination"].includes(workflow.errorCode?.toLowerCase())) != null);
    }

    const hasGeneByGeneLabResponse = (sampleRegistration: SampleRegistration | null) => {
        return sampleRegistration?.previousSample?.workflowSteps?.find(workflow => workflow.name.toLowerCase() === "gxglabprocessing") != null;
    }

    const isGeneByGeneLabCompleted = (sampleRegistration: SampleRegistration | null) => {
        return sampleRegistration?.previousSample?.workflowSteps?.find(workflow => workflow.name.toLowerCase() === "gxglabprocessing" && workflow.isCompleted) != null;
    }

    const addToManifest = (sampleRegistration: SampleRegistration | null, manifestsDestination: ManifestsDestinationOption) => {
        if (["initial", "reflex"].includes(sampleRegistration?.orderType.toLowerCase() ?? ""))
            return true;

        if (sampleRegistration?.previousSample?.workflowSteps.find(workflow => workflow.name.toLowerCase() === "mydnalabprocessing" && workflow.hasError) != null)
            return true;

        if (manifestsDestination === ManifestsDestinationOption.VCGS && hasVcgsLabError(sampleRegistration))
            return true;

        if (manifestsDestination === ManifestsDestinationOption.GeneByGene && hasGeneByGeneLabError(sampleRegistration))
            return true;

        return false;
    }

    const handleScanBarcode = (event: KeyboardEvent) => {
        var sampleId = sampleIdRef.current?.value;
        let regex = new RegExp("LU[ABCDEFGHJKLMNPQRTUVWXYZ2346789]{6}");

        if (event.key !== "Enter" || !sampleId || !regex.test(sampleId!)) {
            return;
        }

        GetRegisteredPatient(sampleId!)
        .then((data: SampleRegistration[]) => {
            if (data && data.length === 0) {
                alert("Could not find any registered patients in Lumi.")
                return
            }

            if (data && data.length > 1) {
                alert("Found two or more registered patients in Lumi.")
            }

            if (data[0].currentStatus === "AwaitingApproval") {
                alert("The order for this sample is awaiting approval by a referring doctor and cannot be received yet. Contact Lumi Support for assistance.");
                return
            }

            if (data[0].currentStatus === "Denied") {
                alert("The order for this sample has been denied approval by a referring doctor and cannot be used. Contact Lumi Support for assistance.");
                return
            }

            if (hasVcgsLabError(data[0]) && !hasGeneByGeneLabError(data[0]) && !hasGeneByGeneLabResponse(data[0])) {
                setModalTextElement(<>VCGS has reported the sample for recollection, but we awaiting a response from GeneByGene.</>);
                setModalOpen(true);
            } else if (!hasVcgsLabError(data[0]) && hasGeneByGeneLabError(data[0]) && !hasVcgsLabResponse(data[0])) {
                setModalTextElement(<>GeneByGene has reported the sample for recollection, but we awaiting a response from VCGS.</>);
                setModalOpen(true);
            }

            if ((data[0].orderType === "recollect") && (!isVcgsLabCompleted(data[0]) || !isGeneByGeneLabCompleted(data[0]))) {
                setModalTextElement(<>VCGS or GeneByGene have not yet returned results. Are you sure you wish to scan this recollect sample in?</>);
                setModalOpen(true);
            }

            if (data[0].patient.pregnantStatus === "Pregnant") {
                setModalTextElement(<>This patient is pregnant!</>);
                setModalOpen(true);
            }

            setCurrentSampleRegistration(data[0]);
        })
        .catch(error => {
            var ErrorMessage = "Error when getting the Lumi patient using the kit barcode.";
            if (error.errors.length > 0) {
                console.error(error.errors[0].message);
                alert(error.errors[0].message);
            } 
            else {
                console.error(ErrorMessage);
                alert(ErrorMessage);
            }
        });
	}

    const handlePatientFirstNameChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        var current = currentSampleRegistration ?? null 
        setCurrentSampleRegistration(UpdatePatientFirstName(current, event.target.value));
    };

    const handlePatientLastNameChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        var current = currentSampleRegistration ?? null 
        setCurrentSampleRegistration(UpdatePatientLastName(current, event.target.value));
    };

    const handlePatientDateOfBirthChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        var current = currentSampleRegistration ?? null 
        setCurrentSampleRegistration(UpdatePatientDateOfBirth(current, event.target.value));
    };

    const handlePatientGenderChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        var current = currentSampleRegistration ?? null 
        setCurrentSampleRegistration(UpdatePatientGender(current, event.target.value));
    };

    const handlePatientEmailChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        var current = currentSampleRegistration ?? null 
        setCurrentSampleRegistration(UpdatePatientEmail(current, event.target.value));
    };

    const handlePatientSampleErrorNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setPatientSampleErrorNote(event.target.value);
    };

    const handleToastClose = () => {
        setToastOpen(false);
        setToastError("");
    }
    
    const handleSavePatientDetails = () => {

        if (!patientSampleCollectionDate) {
            alert("A sample collection date is required.");
            return;
        }

        var patient: Patient = 
            {
                sampleId: currentSampleRegistration?.sampleId ?? "",
                journeyId: currentSampleRegistration?.journeyId ?? "",
                orderId: currentSampleRegistration?.orderId ?? "",
                id: currentSampleRegistration?.patient.id ?? "",
                sex: currentSampleRegistration?.patient.gender ?? "",
                firstName: currentSampleRegistration?.patient.firstName ?? "",
                lastName: currentSampleRegistration?.patient.lastName ?? "",
                email: currentSampleRegistration?.patient.email ?? "",
                dateOfBirth: new Date(currentSampleRegistration?.patient.dateOfBirth ?? ""),
                productCode: currentSampleRegistration?.product.productCode ?? "",
                productDisplayName: currentSampleRegistration?.product.displayName ?? "",
                referringProviderId: currentSampleRegistration?.registrationData.referringProvider?.providerId ?? "",
                referringProviderFirstName: currentSampleRegistration?.registrationData.referringProvider?.firstName ?? "",
                referringProviderLastName: currentSampleRegistration?.registrationData.referringProvider?.lastName ?? "",
                mobilePhone: "",
                homePhone: "",
                sampleCollectionDate: new Date(patientSampleCollectionDate.setHours(11)),
                sampleType: "Buccal Swab",
                sampleErrorNote: "",
                consentStatements: [],
                createdBy: authContext?.user?.getUsername()!,
                orderType: currentSampleRegistration?.orderType ?? "",
                approvedAt: currentSampleRegistration?.approvedAt ?? "",
                addToVCGSManifest: addToManifest(currentSampleRegistration, ManifestsDestinationOption.VCGS),
                addToGeneByGeneManifest: addToManifest(currentSampleRegistration, ManifestsDestinationOption.GeneByGene),
                previousSampleId: currentSampleRegistration?.previousSample?.sampleId ?? "",
                workflowSteps: [],
                dateCreated: new Date(),
            }

            currentSampleRegistration?.registrationData.consentStatements.forEach(consentStatement => {
                var consent: PatientConsentStatement = {
                    type: consentStatement.type,
                    version: consentStatement.version,
                    timestamp: new Date(consentStatement.timestamp ?? "")
                }
                patient.consentStatements.push(consent);
            })

            currentSampleRegistration?.previousSample?.workflowSteps.forEach(workflow => {
                var data: PatientWorkflowSteps = {
                    name: workflow.name,
                    isCompleted: workflow.isCompleted,
                    hasError: workflow.hasError,
                    errorCode: workflow.errorCode ?? ""
                }
                patient.workflowSteps.push(data);
            })

        SpecimenReceived(patient.sampleId, patient.journeyId, patient.orderId, patient.id, patient.productCode)
            .then(response => {
                if (!response.success) {
                    setToastError("Error when attempting to update LUMI with specimenReceived");
                    return;
                }
            })
            .catch(error => {
                var ErrorMessage = "Error when attempting to update LUMI with specimenReceived.";
                if (error.errors.length > 0 && JSON.parse(error.errors[0].errorInfo).success === false) {
                    console.error(ErrorMessage.concat("\r\n").concat(JSON.parse(error.errors[0].errorInfo).message));
                    setToastError(ErrorMessage);
                    // alert(ErrorMessage.concat("\r\n").concat(JSON.parse(error.errors[0].errorInfo).message));
                } 
                else {
                    console.error(ErrorMessage);
                    setToastError(ErrorMessage);
                }
                setToastOpen(true);
            })

        UpsertPatient(patient)   // TODO: When SpecimenReceived returns an error, does it still UpsertPatient and store it ?
            .then(response => {
                // update patient in state
                if (!response.isSuccess) {
                    setToastError("Error when attempting to save patient details");
                }

                // double check if the patient has been stored in the LIMS database.
                GetPatients()
                .then(patients => {
                    var sampleId = currentSampleRegistration?.sampleId ?? "";
                    if (patients.find(patient => patient.sampleId === currentSampleRegistration?.sampleId ?? "") != null) 
                        setToastError("The patient details for sampleId \"" + sampleId + "\" have been stored in LIMS.")
                    else {
                        setToastError("Error inserting patient details for Lumi sample \"" + sampleId + "\" into database. Try again. If this occurs again, please contact IT.");
                        alert("Error inserting patient details for Lumi sample \"" + sampleId + "\" into database. Try again. If this occurs again, please contact IT.");
                    }
                })
                .catch(error => {
                    var message = "Error fetching Lumi Patients data from database. Failed to validate that the patient details have been stored in LIMS.";
                    setToastError(message);
                    console.error(message);
                    console.error(error);
                    alert(message);
                });
				
                // double check if the patient has been stored in the LIMS database.
                GetPatients()
                .then(patients => {
                    var sampleId = currentSampleRegistration?.sampleId ?? "";
                    if (patients.find(patient => patient.sampleId === currentSampleRegistration?.sampleId ?? "") != null) 
                        setToastError("The patient details for sampleId \"" + sampleId + "\" have been stored in LIMS.")
                    else {
                        setToastError("Error inserting patient details for Lumi sample \"" + sampleId + "\" into database. Try again. If this occurs again, please contact IT.");
                        alert("Error inserting patient details for Lumi sample \"" + sampleId + "\" into database. Try again. If this occurs again, please contact IT.");
                    }
                })
                .catch(error => {
                    var message = "Error fetching Lumi Patients data from database. Failed to validate that the patient details have been stored in LIMS.";
                    setToastError(message);
                    console.error(message);
                    console.error(error);
                    alert(message);
                });				
            })
            .catch(error => {
                let errorMessage = "Error when attempting to save patient details. ";
                GraphqlExceptionLogging(error, errorMessage)
                setToastError(errorMessage);
            })
        setToastOpen(true);
	}

    const handleSampleError = () => {

        if (!patientSampleErrorNote) {
            alert("A sample error note is required when reporting the specimen as not acceptable.");
            return
        }

        if (!currentSampleRegistration) {
            alert("Please select a LUMI patient before reporting the specimen as not acceptable.");
            return
        }

        var patient: Patient = 
            {
                sampleId: currentSampleRegistration?.sampleId ?? "",
                journeyId: currentSampleRegistration?.journeyId ?? "",
                orderId: currentSampleRegistration?.orderId ?? "",
                id: currentSampleRegistration?.patient.id ?? "",
                sex: currentSampleRegistration?.patient.gender ?? "",
                firstName: currentSampleRegistration?.patient.firstName ?? "",
                lastName: currentSampleRegistration?.patient.lastName ?? "",
                email: currentSampleRegistration?.patient.email ?? "",
                dateOfBirth: new Date(currentSampleRegistration?.patient.dateOfBirth ?? ""),
                productCode: currentSampleRegistration?.product.productCode ?? "",
                productDisplayName: currentSampleRegistration?.product.displayName ?? "",
                referringProviderId: currentSampleRegistration?.registrationData.referringProvider?.providerId ?? "",
                referringProviderFirstName: currentSampleRegistration?.registrationData.referringProvider?.firstName ?? "",
                referringProviderLastName: currentSampleRegistration?.registrationData.referringProvider?.lastName ?? "",
                mobilePhone: "",
                homePhone: "",
                sampleCollectionDate: new Date(patientSampleCollectionDate!.setHours(11)),
                sampleType: "Buccal Swab",
                sampleErrorNote: patientSampleErrorNote,
                consentStatements: [],
                createdBy: authContext?.user?.getUsername()!,
                orderType: currentSampleRegistration?.orderType ?? "",
                approvedAt: currentSampleRegistration?.approvedAt ?? "",
                addToVCGSManifest: addToManifest(currentSampleRegistration, ManifestsDestinationOption.VCGS),
                addToGeneByGeneManifest: addToManifest(currentSampleRegistration, ManifestsDestinationOption.GeneByGene),
                previousSampleId: currentSampleRegistration?.previousSample?.sampleId ?? "",
                workflowSteps: [],
                dateCreated: new Date()
            }

            currentSampleRegistration?.registrationData.consentStatements.forEach(consentStatement => {
                var consent: PatientConsentStatement = {
                    type: consentStatement.type,
                    version: consentStatement.version,
                    timestamp: new Date(consentStatement.timestamp ?? "")
                }
                patient.consentStatements.push(consent);
            })

            currentSampleRegistration?.previousSample?.workflowSteps.forEach(workflow => {
                
                var data: PatientWorkflowSteps = {
                    name: workflow.name,
                    isCompleted: workflow.isCompleted,
                    hasError: workflow.hasError,
                    errorCode: workflow.errorCode ?? ""
                }
                patient.workflowSteps.push(data);
            })

        SpecimenError(patient.sampleId, patient.journeyId, patient.orderId, patient.id, patient.productCode, patient.sampleErrorNote)
            .then(response => {
                if (!response.success) {
                    setToastError("Error when attempting to update LUMI with specimenError");
                    return;
                }
            })
            .catch(error => {
                var ErrorMessage = "Error when attempting to update LUMI with sample error note.";
                if (error.errors.length > 0 && JSON.parse(error.errors[0].errorInfo).Success === false) {
                    var ConsoleErrorMessage = ErrorMessage
                    ConsoleErrorMessage.concat("\r\n");
                    ConsoleErrorMessage.concat(JSON.parse(error.errors[0].errorInfo).message);
                    console.error(ConsoleErrorMessage);
                    setToastError(ErrorMessage);
                } 
                else {
                    console.error(ErrorMessage);
                    setToastError(ErrorMessage);
                }
                setToastOpen(true);
            })

        UpsertPatient(patient)
            .then(response => {
                // update patient in state
                if (!response.isSuccess) {
                    setToastError("Error when attempting to save patient details");
                    return;
                }
            })
            .catch(error => {
                let errorMessage = "Error when attempting to save patient details. ";
                if (error instanceof Error) 
                    console.error(errorMessage + error.message)
                else
                    console.error(errorMessage + String(error));

                setToastError(errorMessage);
            })
        setToastOpen(true);
	}

    const toggleModal = () => {
        setModalOpen(modalOpen => !modalOpen);
    }
    
    return (
        <>
        <ContentWrapper>
            <Snackbar open={toastOpen} autoHideDuration={6000} onClose={handleToastClose}>
                <MuiAlert onClose={handleToastClose} variant="filled" severity={toastError === "" ? "success" : "error"} sx={{ width: '100%' }}>
                    {toastError === "" ? "Success!" : toastError}
                </MuiAlert>
            </Snackbar>
            <SectionWrapper>
                <Typography gutterBottom variant="h4" component="div">
                    Specimen Reception
                </Typography>
            </SectionWrapper>
            <Divider variant="middle" />
            <Box sx={{ minWidth: 120 }}>
                    <SectionWrapper>
                        <Typography gutterBottom variant="h6" component="span">
                        Scan Barcode:  {" "}
                        </Typography>
                        <TextField 
                            id="standard-basic" 
                            label="" 
                            variant="standard" 
                            inputRef={sampleIdRef}
                            onKeyUp={handleScanBarcode}
                        />
                    </SectionWrapper>
            </Box>
            <Divider variant="middle" />
            <Typography gutterBottom variant="h6" component="div">
                Patient details
            </Typography>
            <Box sx={{ minWidth: 120 }}>
                    <SectionWrapper>
                        <Typography gutterBottom variant="h6" component="span">
                        first name:
                        </Typography>
                        <TextField 
                            id="first-name" 
                            label="" 
                            variant="standard"
                            value={currentSampleRegistration?.patient.firstName ?? ""}
							onChange={handlePatientFirstNameChange}
                        />
                    </SectionWrapper>
                    <SectionWrapper>
                        <Typography gutterBottom variant="h6" component="span">
                        last name:  {" "}
                        </Typography>
                        <TextField 
                            id="last-name" 
                            label="" 
                            variant="standard" 
                            value={currentSampleRegistration?.patient.lastName ?? ""}                            
							onChange={handlePatientLastNameChange}
                        />
                    </SectionWrapper>
                    <SectionWrapper>
                        <Typography gutterBottom variant="h6" component="span">
                        date of birth:  {" "}
                        </Typography>
                        <TextField 
                            id="dateofbirth" 
                            label="" 
                            variant="standard" 
                            value={currentSampleRegistration?.patient.dateOfBirth ?? ""}
							onChange={handlePatientDateOfBirthChange}
                        />
                    </SectionWrapper>
                    <SectionWrapper>
                        <Typography gutterBottom variant="h6" component="span">
                        gender:  {" "}
                        </Typography>
                        <TextField 
                            id="gender" 
                            label="" 
                            variant="standard" 
                            value={currentSampleRegistration?.patient.gender ?? ""}
							onChange={handlePatientGenderChange}
                        />
                    </SectionWrapper>
                    <SectionWrapper>
                        <Typography gutterBottom variant="h6" component="span">
                        email:  {" "}
                        </Typography>
                        <TextField 
                            id="email" 
                            label="" 
                            variant="standard" 
                            value={currentSampleRegistration?.patient.email ?? ""}
							onChange={handlePatientEmailChange}
                        />
                    </SectionWrapper>
            </Box>
            <Divider variant="middle" />
            <Box sx={{ minWidth: 120 }}>
                    <SectionWrapper>
                        <Typography gutterBottom variant="h6" component="span">
                        product:  {currentSampleRegistration?.product.displayName}
                        </Typography>
                    </SectionWrapper>
            </Box>
            <Divider variant="middle" />
            <Box sx={{ minWidth: 120 }}>
                    <SectionWrapper>
                        <Typography gutterBottom variant="h6" component="span">
                        order type:  {currentSampleRegistration?.orderType ?? ""}
                        </Typography>
                    </SectionWrapper>
            </Box>
            <Divider variant="middle" />
            <Box sx={{ minWidth: 120 }}>
                    <SectionWrapper>
                        <Typography gutterBottom variant="h6" component="span">
                        sample collection date:  {" "}
                        </Typography>
                        <DatePicker id="samplecollectiondate" showIcon dateFormat="dd/MM/yyyy" autoComplete="new-password" selected={patientSampleCollectionDate} onChange={(date) => setPatientSampleCollectionDate(date)} />
                    </SectionWrapper>
            </Box>
            <Divider variant="middle" />
            <Box sx={{ minWidth: 120 }}>
            <SectionWrapper>
                        <Typography gutterBottom variant="h6" component="span">
                        sample error note:  {" "}
                        </Typography>
                        <TextField 
                            id="sampleerrornote" 
                            label="" 
                            variant="standard" 
                            onChange={handlePatientSampleErrorNoteChange}
                        />
                    </SectionWrapper>
            </Box>
            <Divider variant="middle" />
            <Box sx={{ minWidth: 120 }}>
                <Button color="error" variant="contained" onClick={handleSampleError}>Report error</Button>
                <Button color="success" variant="contained" onClick={handleSavePatientDetails}>Save</Button>
            </Box>
        </ContentWrapper>
        <Modal
            open={modalOpen}
            onClose={() => toggleModal()}
        >
            <Box sx={ModalStyle}>
                <WarningIcon />
                <Typography variant="h6" component="h2">
                    {modalTextElement}
                </Typography>
                <Button color="success" variant="contained" onClick={toggleModal}>OK</Button>
            </Box>
        </Modal>
        </>
    )
};

export default SpecimenReceptionPage;